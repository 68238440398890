<template>
  <div>
    <EditCustomer />
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Sr No.</th>
          <th scope="col" class="text-center">Customer</th>
          <th scope="col" class="text-center">Balance</th>
          <th scope="col" class="text-center">Due Date</th>
          <th scope="col" class="text-center">Last Invoice</th>
          <th scope="col" class="text-center">Contact Person</th>
          <th scope="col" class="text-center">Phone Number</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Actions</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedCustomers.length > 0">
        <tr v-for="(customer, index) in displayedCustomers" :key="index">
          <td>
            <div class="company-link">
              <span>C-{{ index + 1 }}</span>
            </div>
          </td>
          <td v-if="customer.picture == null">
            <div class="company-details">
              <div class="company-logo">
                <img src="../../assets/images/user-image.png" alt="" />
              </div>
              <h6 class="company-name">{{ customer.customer_name }}</h6>
            </div>
          </td>
          <td v-else>
            <div class="company-details">
              <div class="company-logo">
                <img :src="API_URL + customer.picture" alt="" />
              </div>
              <h6 class="company-name">{{ customer.customer_name }}</h6>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span></span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span></span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span></span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ customer.contact_person }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ customer.phone_primary }}</span>
            </div>
          </td>
          <td v-if="customer.status == 1" class="text-center">
            <button class="active">Active</button>
          </td>
          <td v-else-if="customer.status == 2" class="text-center">
            <button class="terminated">Not Active</button>
          </td>
          <td class="text-center">
            <div class="actions-btn">
              <i class="fa fa-pencil-alt" @click="callModal(customer)"> </i>
              <i class="fa fa-trash" @click="deleteCustomer(customer.id)"> </i>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedCustomers.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import EditCustomer from "../../components/customers/customerform/EditCustomer.vue";
export default {
  name: "CustomerTable",
  props: {
    displayedCustomers: Array,
    showLoader: Number,
  },
  data() {
    return {
      API_URL: this.$customerStorageURL,
    };
  },
  components: {
    EditCustomer,
  },

  methods: {
    callModal: function(customer) {
      this.$root.$emit("openEditCustomer", customer);
    },

    async deleteCustomer(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Customer ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(process.env.VUE_APP_API_URL + "customer/delete", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removeCustomer", id);
                this.$swal("Deleted!", "Customer has been deleted.", "success");
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
