<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditCustomerModal"
      ref="openModalEditCustomer"
    ></button>
    <div
      class="modal fade"
      id="EditCustomerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Edit Customer</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalEditCustomer"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 col-md-4 px-0 pr-md-2">
                        <div class="user-profile-image">
                          <div id="preview" class="profile-image">
                            <img v-if="url" :src="url" />
                            <img
                              v-else-if="pictureDetails"
                              :src="API_URL + pictureDetails"
                            />
                            <img
                              v-else
                              src="../../../assets/images/user-image.png"
                              alt=""
                            />
                          </div>

                          <div
                            class="change-profile-image"
                            @click="openinput()"
                          >
                            <form>
                              <input
                                type="file"
                                id="vue-file-upload-input-profile-photo"
                                class="d-none"
                                @change="onFileChange"
                                accept="image/png, image/gif, image/jpeg"
                              />

                              <div class="change-icon">
                                <span class="text">Update Profile Picture</span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-8 px-0">
                        <div class="row">
                          <div class="col-12 px-0">
                            <div class="form-group">
                              <label for="customer_name"
                                >Customer Name <span class="astrick">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="customer_name"
                                placeholder="Customer Name"
                                autocomplete="off"
                                v-model.trim="$v.details.customer_name.$model"
                                :class="{
                                  'is-invalid': $v.details.customer_name.$error,
                                  'is-valid': !$v.details.customer_name
                                    .$invalid,
                                }"
                              />
                              <div class="invalid-tooltip">
                                <span v-if="!$v.details.customer_name.required"
                                  >Customer name is required</span
                                >
                                <span v-if="!$v.details.customer_name.minLength"
                                  >Customer name must have at least
                                  {{
                                    $v.details.customer_name.$params.minLength
                                      .min
                                  }}</span
                                >
                                <span v-if="!$v.details.customer_name.maxLength"
                                  >Customer name must have at most
                                  {{
                                    $v.details.customer_name.$params.maxLength
                                      .max
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="col-12 px-0">
                            <div class="form-group">
                              <label for="contact_person"
                                >Contact Person <span class="astrick">*</span>
                              </label>
                              <input
                                type="text"
                                v-model.trim="$v.details.contact_person.$model"
                                class="form-control"
                                id="contact_person"
                                placeholder="Contact Person"
                                :class="{
                                  'is-invalid':
                                    $v.details.contact_person.$error,
                                  'is-valid': !$v.details.contact_person
                                    .$invalid,
                                }"
                                autocomplete="off"
                              />
                              <div class="invalid-tooltip">
                                <span v-if="!$v.details.contact_person.required"
                                  >Contact Person is required</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-md-6 px-0 pr-md-2">
                        <div class="form-group">
                          <label for="contact_first_name"
                            >Contact First Name <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.contact_first_name.$model"
                            class="form-control"
                            id="contact_first_name"
                            placeholder="Contact First Name"
                            :class="{
                              'is-invalid':
                                $v.details.contact_first_name.$error,
                              'is-valid': !$v.details.contact_first_name
                                .$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.contact_first_name.required"
                              >Name is required</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 px-0">
                        <div class="form-group">
                          <label for="phone_primary"
                            >Phone (primary) <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.phone_primary.$model"
                            class="form-control"
                            id="phone_primary"
                            placeholder="Phone (primary)"
                            :class="{
                              'is-invalid': $v.details.phone_primary.$error,
                              'is-valid': !$v.details.phone_primary.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.phone_primary.required"
                              >Phone is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0 pr-md-2">
                        <div class="form-group">
                          <label for="phone_alternative"
                            >Phone (alternative) <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.phone_alternative.$model"
                            class="form-control"
                            id="phone_alternative"
                            placeholder="Phone (alternative)"
                            :class="{
                              'is-invalid': $v.details.phone_alternative.$error,
                              'is-valid': !$v.details.phone_alternative
                                .$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.phone_alternative.required"
                              >Phone is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0">
                        <div class="form-group">
                          <label for="fax"
                            >Fax <span class="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            v-model.trim="$v.details.fax.$model"
                            class="form-control"
                            id="fax"
                            placeholder="Fax"
                            :class="{
                              'is-invalid': $v.details.fax.$error,
                              'is-valid': !$v.details.fax.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.fax.required"
                              >Fax is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-0 pr-md-2">
                        <div class="form-group">
                          <label for="email"
                            >Email <span class="astrick">*</span>
                          </label>
                          <input
                            type="email"
                            v-model.trim="$v.details.email.$model"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            :class="{
                              'is-invalid': $v.details.email.$error,
                              'is-valid': !$v.details.email.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.email.required"
                              >Email is required</span
                            >
                            <span v-if="!$v.details.email.email"
                              >Email must be valid
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-md-6 px-0">
                        <div class="form-group">
                          <label for="info">Additional Printed Info</label>
                          <input
                            type="text"
                            v-model.trim="$v.details.info.$model"
                            class="form-control"
                            id="info"
                            placeholder="Additional Printed Info"
                            :class="{
                              'is-invalid': $v.details.info.$error,
                              'is-valid': !$v.details.info.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.info.required"
                              >Info is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div class="form-group">
                          <label for="address"
                            >Address <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            v-model.trim="$v.details.address.$model"
                            class="form-control"
                            id="address"
                            placeholder="Address"
                            :class="{
                              'is-invalid': $v.details.address.$error,
                              'is-valid': !$v.details.address.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.address.required"
                              >Address is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-0">
                        <div
                          class="form-group d-flex align-items-center justify-content-start"
                        >
                          <input
                            type="checkbox"
                            class="mr-2"
                            v-model="details.status"
                            id="customerActive"
                          />
                          <label class="form-check-label" for="customerActive"
                            >This customer is active</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2"
                @click="updateCustomer(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Update</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

import axios from "axios";
import $ from "jquery";
export default {
  name: "EditCustomer",
  data: function() {
    return {
      details: {
        id: "",
        customer_name: "",
        contact_person: "",
        contact_first_name: "",
        address: "",
        phone_primary: "",
        phone_alternative: "",
        fax: "",
        email: "",
        info: "",
        status: false,
      },
      pictureDetails: null,
      isLoadingArray: [],
      API_URL: this.$customerStorageURL,
      url: null,
      profilePhoto: null,
      oldPicture: true,
    };
  },
  validations: {
    details: {
      customer_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25),
      },
      contact_person: {
        required,
      },
      contact_first_name: {
        required,
      },
      address: { required },
      phone_primary: { required },
      phone_alternative: { required },
      fax: { required },
      email: {
        required,
        email,
      },
      info: { required },
    },
  },
  methods: {
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    updateCustomer: function(index) {
      var formData = new FormData();
      formData.append("customer_name", this.details.customer_name);
      formData.append("id", this.details.id);
      formData.append("email", this.details.email);
      formData.append("contact_person", this.details.contact_person);
      formData.append("contact_first_name", this.details.contact_first_name);
      formData.append("address", this.details.address);
      formData.append("phone_primary", this.details.phone_primary);
      formData.append("phone_alternative", this.details.phone_alternative);
      formData.append("fax", this.details.fax);
      formData.append("info", this.details.info);
      var dataStatus = this.details.status == true ? 1 : 2;
      formData.append("status", dataStatus);
      if (this.profilePhoto != null) {
        formData.append("picture", this.profilePhoto);
      }
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "customer/update", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$swal("Udpated!", "Customer has been updated.", "success");
            this.$root.$emit("reloadCustomer");
            var elem = this.$refs.closeModalEditCustomer;
            if (elem) {
              elem.click();
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openEditCustomer", (customer) => {
      var elem = this.$refs.openModalEditCustomer;
      if (elem) {
        this.details.customer_name = customer.customer_name;
        this.details.contact_person = customer.contact_person;
        this.details.contact_first_name = customer.contact_first_name;
        this.details.address = customer.address;
        this.details.phone_primary = customer.phone_primary;
        this.details.phone_alternative = customer.phone_alternative;
        this.details.fax = customer.fax;
        this.details.email = customer.email;
        this.details.info = customer.info;
        this.details.id = customer.id;
        this.pictureDetails = customer.picture;
        this.profilePhoto = null;
        elem.click();
        if (customer.status == 1) {
          this.details.status = true;
          $("#customerActive").prop("checked", true);
        } else {
          this.details.status = false;
          $("#customerActive").prop("checked", false);
        }
      }
    });
  },
};
</script>

<style></style>
