<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <Top />
        <CustomerTable
          :displayedCustomers="customer"
          :showLoader="showLoader"
        />
        <DatatableBottom :page="pager" :module="moduleName" :count="count" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import Top from "../components/customers/CustomerTop.vue";
import CustomerTable from "../components/customers/CustomerTable.vue";
import DatatableBottom from "../components/tableview/DatatableBottom.vue";
import axios from "axios";

export default {
  name: "Customers",

  components: {
    Navbar,
    LeftMenu,
    Top,
    CustomerTable,
    DatatableBottom,
  },

  data: function() {
    return {
      moduleName: "",
      errorMessage: null,
      customer: [],
      showLoader: 0,
      page: 0,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
    };
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.current_page) {
          this.showLoader = 1;
          this.fetchCustomer(page);
        }
      },
    },
  },

  methods: {
    fetchCustomer(page) {
      axios
        .post(`${process.env.VUE_APP_API_URL}customer/listing?page=${page}`, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.customer = response.data.data.data;
            this.pager = response.data.data;
            this.count = response.data.data.total;
            this.moduleName =
              response.data.data.total > 0 ? "customers" : "customer";
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },

  mounted() {
    this.$root.$on("removeCustomer", (id) => {
      const index = this.customer.findIndex((c) => c.id === id);
      this.customer.splice(index, 1);
      this.count = this.count - 1;
      this.pager.total = this.pager.total - 1;
    });

    this.$root.$on("reloadCustomer", () => {
      this.fetchCustomer(1);
    });
  },
};
</script>
