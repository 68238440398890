<template>
  <div class="top-wrapper">
    <h6>Customers Management</h6>
    <div>
      <button class="custom-btn2 mr-2" @click="openCustomerFilter">
        Customer Filter
      </button>
      <router-link class="custom-btn2" to="/add-customer"
        >Create Customer</router-link
      >
    </div>

    <div class="customer-filter-pop" v-if="customerFilter">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h6>
          Customers Statements
        </h6>
        <span class="close-icon" @click="closeCustomerFilter">&times;</span>
      </div>
      <h6>Customer Filter</h6>
      <div class="d-flex align-items-center my-3">
        <input type="checkbox" class="mr-2" id="sort" />
        <label class="form-check-label" for="sort">Sort by Date</label>
      </div>
      <div class="ml-3">
        <input
          type="radio"
          id="descending"
          name="sort"
          value="Descending"
          class="mr-2"
        />
        <label for="descending">Descending</label>
      </div>
      <div class="ml-3">
        <input
          type="radio"
          id="ascending"
          name="sort"
          value="Ascending"
          class="mr-2"
        />
        <label for="ascending">Ascending</label><br />
      </div>
      <div>
        <p>Select Period</p>
        <div class="ml-3">
          <input
            type="radio"
            id="auto"
            name="sort"
            value="Automatically use month or oldest due"
            class="mr-2"
          />
          <label for="auto">Automatically use month or oldest due</label>
        </div>
        <div class="ml-3">
          <input
            type="radio"
            id="period"
            name="sort"
            value="Enter statement period"
            class="mr-2"
          />
          <label for="period">Enter statement period</label>
        </div>
        <div class="form-group d-flex align-items-center justify-content-end">
          <label for="startDate" class="mr-2">Start Date:</label>
          <input type="text" class="pl-2" placeholder="Enter Start Date" />
        </div>
        <div class="form-group d-flex align-items-center justify-content-end">
          <label for="startDate" class="mr-2">Endt Date:</label>
          <input type="text" class="pl-2" placeholder="Enter End Date" />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-around">
        <div>
          <input
            type="radio"
            id="print"
            name="view"
            value="Print"
            class="mr-2"
          />
          <label for="print">Print</label>
        </div>
        <div>
          <input
            type="radio"
            id="printPreview"
            name="view"
            value="Print Preview"
            class="mr-2"
          />
          <label for="printPreview">Print Preview</label>
        </div>
        <div>
          <input
            type="radio"
            id="email"
            name="view"
            value="Email"
            class="mr-2"
          />
          <label for="email">Email</label>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-around">
        <div>
          <input type="radio" id="fax" name="view" value="Fax" class="mr-2" />
          <label for="fax">Fax</label>
        </div>
        <div>
          <input
            type="radio"
            id="saveaspdf"
            name="view"
            value="Save as PDF"
            class="mr-2"
          />
          <label for="saveaspdf">Save as PDF</label>
        </div>
      </div>
      <button
        type="button"
        class="custom-btn2"
        @click="payment(0)"
        :disabled="isLoadingArray[0]"
      >
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerTop",
  data: function() {
    return {
      customerFilter: false,
      isLoadingArray: [],
    };
  },
  methods: {
    openCustomerFilter: function() {
      this.customerFilter = !this.customerFilter;
    },
    closeCustomerFilter: function() {
      this.customerFilter = false;
    },
  },
};
</script>

<style></style>
